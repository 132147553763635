<template>
  <footer>
    <div class="support">
      <span>Supported by</span>
      <a href="https://www.pilates.com/"><img src="@/assets/images/logo-bb-h.svg" alt="Balanced Body logo" /></a>
    </div>
    <nav>
      <router-link to="/contact">Contact Us</router-link>
      <a href="https://www.pilates.com/company/balanced-body-privacy-policy">Privacy</a>
    </nav>
  </footer>
</template>

<script>
  export default {
    name: "AppFooter",
  }
</script>

<style scoped>
footer {
  height: 155px;
  margin: 0;
  padding: 10px 30px 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: center;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: min(120px, 100%);
}

nav a {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 11rem;
  color: #555658;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}

nav a:first-of-type {
  color: var(--link);
}

.support {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  margin-bottom: 20px;
}

.support span {
  font-family: var(--bry-pro-med);
  font-size: 12rem;
  line-height: 0.5;
  font-weight: 500;
  color: #5e5f61;
  text-transform: uppercase;
}

.support img {
  width: 186px;
}

@media only screen and (min-width: 650px) {
  footer {
    max-width: 1024px;
    margin: 0 auto;
    padding: 30px min(10%, 85px) 0;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }

  nav {
    order: 1;
  }

  .support {
    order: 2;
  }
}
</style>